<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_shipAchieveAdd" />
      <!-- （画面名）出荷実績登録-->
      <v-container fluid>
        <!-- 検索項目-->
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- 出荷先-->
            <v-text-field
              dense
              readonly
              v-model="shippingSelected"
              :label="$t('label.lbl_shippingTo')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="textbox-arrivalNo">
            <!-- 出荷No-->
            <v-text-field
              readonly
              dense
              class="txt-single"
              v-model="txt_slipNo"
              maxlength="50"
              :label="$t('label.lbl_shippingNo')"
            />
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 出荷予定日-->
            <v-text-field
              readonly
              dense
              v-model="shippingAchieveDate"
              :label="$t('label.lbl_shippingScheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- 有償無償-->
            <v-text-field
              readonly
              dense
              v-model="isPaidNm"
              :label="$t('label.lbl_isPaid')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer" style="float: left">&nbsp;</span>

          <div class="search-autocomplete" style="float: left">
            <!-- 処理区分-->
            <v-text-field
              dense
              readonly
              v-model="processDivSelected"
              :label="$t('label.lbl_processingdivision')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div class="search-autocomplete first-search-item" style="float: left">
            <!-- 輸送会社-->
            <v-text-field
              dense
              readonly
              v-model="truck"
              :label="$t('label.lbl_trackName')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 登録ボタン-->
              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_insert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="updateAchieve"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- ロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <span>
                {{ header.text }}<br />
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </template>

            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                @click="checkBoxList(item, index)"
                style="transform: scale(2)"
                :disabled="item.deleteFlg == 1"
              />
            </template>
            <!-- 品番/付帯 -->
            <template v-slot:[`item.itemCd`]="{ item }">
              <div class="listNo-style">{{ item.itemCd }}</div>
              <div class="listNo-style">{{ item.incidental }}</div>
            </template>
            <!-- ロット/S/N-->
            <template v-slot:[`item.lot`]="{ item }">
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 350px" v-bind="attrs" v-on="on">
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 戻るボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :shpSchListFlg="infoDialog.shpSchListFlg"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
// import { controlNumberUtil } from "../../assets/scripts/js/ControlNumberUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: appConfig.SCREEN_ID.P_SHP_006,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    loadingCounter: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 取引先
    suppliersSelected: "",
    // 入荷元
    shippingSelected: "",
    // 入荷No
    txt_slipNo: "",
    // 入荷実績日
    shippingAchieveDate: "",
    // 有償無償
    isPaidNm: "",
    // 処理区分
    processDivSelected: "",
    // メニュー
    openMenu: null,
    // メッセージダイアログ
    dialogMessage: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 一覧index
    editedIndex: -1,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    checkFlg: false,
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    productListIdx: -1,

    // ヘッダ
    headerItems: [
      {
        // 選択
        text: i18n.tc("label.lbl_choice"),
        value: "check",
        width: "3%",
        sortable: false,
        align: "center",
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "toName",
        width: "7%",
        align: "left",
        sortable: true,
      },
      {
        // 予定日
        text: i18n.tc("label.lbl_schDate"),
        value: "sheduleDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      {
        // 品番
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "12%",
        align: "left",
      },
      {
        // 数量
        text: i18n.tc("label.lbl_quantity"),
        value: "productQty",
        width: "4%",
        align: "right",
        sortable: false,
      },
      {
        // 管理No
        text: i18n.tc("label.lbl_managementNo"),
        value: "managementNo",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // ステータス（TODO:名称仮）
      { text: i18n.tc("label.lbl_status"), value: "status", width: "7%", align: "center" },
    ],
    // 一覧選択チェックボックス
    listCheckbox: [],
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),

  created() {
    // 取引先(実績登録画面表示用)
    this.suppliersSelected = this.$route.params.suppliersNm;
    // 出荷先名(実績登録画面表示用)
    this.shippingSelected = this.$route.params.shippingNm;
    // 出荷先SID（明細）
    this.toSid = this.$route.params.toSid;
    // 出荷指示No（明細）
    this.txt_slipNo = this.$route.params.shipNo;
    // 有償無償名
    this.isPaidNm = this.$route.params.freeCostDivName;
    // 有償無償区分
    this.isPaid = this.$route.params.isPaid;
    // 品質区分
    this.qualityDiv = this.$route.params.qualityDiv;
    // 有償無償区分
    this.isPaid = this.$route.params.isPaid;
    // 責任区分
    this.blameDiv = this.$route.params.blameDiv;
    // 処理区分名
    this.processDivSelected = this.$route.params.processingdivision;
    // 輸送会社名
    this.truck = this.$route.params.truck;
    // 出荷予定日
    this.shippingAchieveDate = this.$route.params.shippingScheduleDate;
    // 管理No
    this.itemManageNo = this.$route.params.itemManageNo;
    // 出荷指示SID
    this.shipSid = this.$route.params.shipSid;
    // 更新日時
    this.scheduleUpdateDatetime = this.$route.params.updateDatetime;
    // 予定明細
    this.dtlList = this.$route.params.dtlList;
    // 入荷予定一覧の検索条件
    // 取引先SID
    this.searchSuppliersSelected = this.$route.params.suppliersSelected;
    // 出荷先SID
    this.searchShippingSelected = this.$route.params.shippingSelected;
    // 出荷予定日From
    this.searchShippingScheduleDateFrom = this.$route.params.shippingScheduleDateFrom;
    // 出荷予定日TO
    this.searchShippingScheduleDateTo = this.$route.params.shippingScheduleDateTo;
    // 出荷予定日FromCal
    this.searchShippingScheduleDateFromCal = this.$route.params.shippingScheduleDateFromCal;
    // 出荷予定日ToCal
    this.searchShippingScheduleDateToCal = this.$route.params.shippingScheduleDateToCal;
    // 発行条件（送り状）コード値
    this.searchIssueTermsSelected = this.$route.params.issueTermsSelected;
    // 発行条件（荷札）コード値
    this.searchTagTermsSelected = this.$route.params.tagTermsSelected;
    //作成日From
    this.createDateFrom = this.$route.params.createDateFrom;
    //作成日To
    this.createDateTo = this.$route.params.createDateTo;
    // 輸送会社名
    this.searchTruckNm = this.$route.params.truckNm;
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;

    //出荷指示から取得した値をセット
    this.getDetail();
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(出荷予定一覧から値受け取る)
     */
    init() {
      // ceatedにて記載
    },

    /**
     * メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 出荷予定一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        this.checkFlg = true;

        this.$router.push({
          name: appConfig.SCREEN_ID.P_SHP_003,
          params: {
            // 取引先SID
            searchSuppliersSelected: this.searchSuppliersSelected,
            // 出荷先SID
            searchShippingSelected: this.searchShippingSelected,
            // 出荷予定日From
            searchShippingScheduleDateFrom: this.searchShippingScheduleDateFrom,
            // 出荷予定日TO
            searchShippingScheduleDateTo: this.searchShippingScheduleDateTo,
            // 出荷予定日FromCal
            searchShippingScheduleDateFromCal: this.searchShippingScheduleDateFromCal,
            // 出荷予定日ToCal
            searchShippingScheduleDateToCal: this.searchShippingScheduleDateToCal,
            // 輸送会社名
            searchTruckNm: this.searchTruckNm,
            // 発行条件（送り状）
            searchIssueTermsSelected: this.searchIssueTermsSelected,
            // 発行条件（荷札）
            searchTagTermsSelected: this.searchTagTermsSelected,
            //作成日From
            createDateFrom: this.createDateFrom,
            //作成日To
            createDateTo: this.createDateTo,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 出荷先一覧
            shippingList: this.$route.params.shippingList,
            // 出荷先名表示用
            shippingNm: this.$route.params.shippingNm,
            // 出荷予定一覧画面の検索パラメータ
            searchParam: this.$route.params.searchParam,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入力カンマ処理 数量
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
    },

    /**
     * 入力カンマ処理 満数荷姿
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
    },

    /**
     * 入力カンマ処理 端数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
    },

    /**
     * 明細API接続(出荷指示API)
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 出荷指示SID
      config.params.outListSid = this.shipSid;

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingAchieveDate);
      // console.debug("getDetail() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_SHIPPINGORDERS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // console.debug("getDetail() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              // 出荷指示リストを取得
              jsonData.resIdv.outOrder.forEach((row) => {
                let status = {};
                if (row.deleteFlg == 1) {
                  // 出荷済
                  status = i18n.tc("label.lbl_alreadyShip");
                } else {
                  // 出荷未済
                  status = i18n.tc("label.lbl_notYetShip");
                }
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // 出荷予定日
                  sheduleDate: dateTimeHelper.convertUTC2JST(row.outScheduleDate).substr(0, 10),
                  // 出荷伝票明細SID
                  outListSubSid: row.outListSubSid,
                  // 出荷指示SID
                  wareOutInstSid: row.outInstSid,
                  // 営業所SID
                  officeSid: row.officeSid,
                  // 取引先SID
                  clientSid: row.clientSid,
                  // 取引先名
                  clientName: row.clientName,
                  // 工程CD
                  procCd: row.procCd,
                  // 商品標準SID
                  itemStandardSid: row.itemStandardSid,
                  // 品番
                  itemCdShow: row.itemCd,
                  itemCd: row.itemCd.substring(0, 50).trim(),
                  //付帯
                  incidental: row.itemCd.slice(-50).trim(),
                  // ロット、S/N
                  lot: lotObj.lot,
                  // ロット
                  lotShow: row.lotNo,
                  // 期限日、付属キー１、付属キー２
                  dueDate: lotObj.dueDate,
                  // 管理No
                  managementNo: row.itemManageNo,
                  // FromSID
                  fromSid: row.fromSid,
                  // ToSID
                  toSid: row.toSid,
                  // 出荷先名
                  toName: row.toName,
                  // 有償無償区分
                  freeCostDiv: row.freeCostDiv,
                  // 品質区分
                  qualityDiv: row.qualityDiv,
                  // 責任区分
                  blameDiv: row.blameDiv,
                  // 数量
                  productQty: commonUtil.formatToCurrency(Number(row.signStock)),
                  // チェックボックス
                  check: false,
                  // ステータス（名称仮）
                  status: status,
                  // 削除フラグ
                  deleteFlg: row.deleteFlg,
                  //ロケーションSID
                  locationSid: row.locationSid,
                  // 流通加工区分
                  distDiv: row.distDiv,
                  // 前工程コード
                  aheadProcCd: row.aheadProcCd,
                  // 次工程コード
                  nextProcCd: row.nextProcCd,
                });
              });
              this.inputList = list;

              // 実績に明細の更新日時を追加する
              for (var l = 0; l < this.dtlList.length; l++) {
                for (var k = 0; k < this.inputList.length; k++) {
                  if (this.inputList[k].outListSubSid == this.dtlList[l].outListSubSid) {
                    this.inputList[k].updateDatetime = this.dtlList[l].updateDatetime;
                  }
                }
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * チェックボックス押下処理
     */
    checkBoxList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        this.inputList[index].check = true;
      } else {
        // チェックを外した場合
        this.inputList[index].check = false;
      }
    },

    /**
     * 登録ボタン押下
     */
    rcvAchAdd() {
      var cnt = 0;
      var completeCnt = 0;
      // 全て出済の場合、チェック
      for (var j = 0; j < this.inputList.length; j++) {
        if (this.inputList[j].deleteFlg == "0") {
          completeCnt = j + 1;
        }
      }
      if (completeCnt == 0) {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.errorFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_002_E");
        return;
      }

      // チェックボックス、存在チェック
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          cnt = i + 1;
        }
      }
      if (cnt > 0) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.errorFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 実績登録POSTAPI接続
     */
    updateAchieve() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_006;
      // チェックリスト追加
      var bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          // チェックを入れたレコードのみ設定
          var inputMapBara = {};
          // 操作画面名
          inputMapBara.opeDispName = i18n.tc("label.lbl_shipAchieveAddpc");
          // 工程CD（出荷:500）
          inputMapBara.procCd = "500";
          // 入出区分（出:01）
          inputMapBara.inOutDiv = "01";
          // 日時
          // let today = new Date();
          // today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          var nowDate = dateTimeHelper.convertUTC();
          inputMapBara.yyyymmddHhmmss = nowDate;
          // ロット
          inputMapBara.lotNo = this.inputList[i].lotShow;
          // 商品標準SID
          inputMapBara.itemStandardSid = this.inputList[i].itemStandardSid;
          // 品番
          inputMapBara.itemCd = this.inputList[i].itemCdShow;
          // 管理No
          inputMapBara.itemManageNo = this.inputList[i].managementNo;
          // 修正数量(端数)
          inputMapBara.stock = this.inputList[i].productQty.replaceAll(",", "");
          // FromSID（営業所SID）
          inputMapBara.fromSid = sessionStorage.getItem("sales_office_sid");
          // ToSID（出荷先SID）
          inputMapBara.toSid = this.toSid;
          // 有償無償区分
          inputMapBara.freeCostDiv = this.inputList[i].freeCostDiv;
          // 品質区分
          inputMapBara.qualityDiv = this.inputList[i].qualityDiv;
          // 責任区分
          inputMapBara.blameDiv = this.inputList[i].blameDiv;
          // 出荷伝票明細SID
          inputMapBara.outListSubSid = this.inputList[i].outListSubSid;
          // 営業所SID
          inputMapBara.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          inputMapBara.clientSid = this.searchSuppliersSelected;
          // 予定更新日時
          inputMapBara.scheduleUpdateDatetime = this.scheduleUpdateDatetime;
          // 明細更新日時
          inputMapBara.detailUpdateDatetime = this.inputList[i].updateDatetime;
          // 出荷指示SID
          inputMapBara.outInstSid = this.inputList[i].wareOutInstSid;
          // ロケーションSID
          inputMapBara.locationSid = this.inputList[i].locationSid;
          // 流通加工区分
          inputMapBara.distDiv = this.inputList[i].distDiv;
          // 前工程コード
          inputMapBara.aheadProcCd = this.inputList[i].aheadProcCd;
          // 次工程コード
          inputMapBara.afterProcCd = this.inputList[i].nextProcCd;

          bodyList.push(inputMapBara);
        }
      }

      // 実績一覧に追加
      body.reqIdv.actuals = bodyList;
      // console.debug("updateAchieve() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_ACTUALS, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("updateAchieve() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // ホームボタン
              this.infoDialog.homePageFlag = true;
              // 出荷予定一覧ボタン
              this.infoDialog.shpSchListFlg = true;
              this.infoDialog.firstPageFlag = false;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
</style>
